import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { Banner } from '../components/banner';
import { TiggbeeContactUs } from '../components/tiggbee-contact-us';
import { HomepageBannerContent } from '../components/homepage-banner-content';
import { HomepageContent } from '../components/homepage-content';
// import home_page_banner from '../images/home-page-banner.png';

class IndexPage extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
      }
  }

  render() {
      const html = <>
        <Layout>
          <SEO title="Home Page" />
          <Banner
            imgURL='home-page-banner.png'
            imgHeight={767}
            content={<HomepageBannerContent />}
          />
          <HomepageContent />
          <TiggbeeContactUs
            titleElement={<>Our Mission is to empower our students regardless of economic or geographic barriers with the hope and knowledge of the opportunities to be happy and successful individuals.</>}
          />
        </Layout>
      </>;
      return (
          html
      );
  }
}

export default IndexPage
