import React from "react"
import "./content.css"
import { PartnerScroller } from "./partner-scroller"

export class HomepageContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  setTechnologyPart = () => {
    const content = (
      <>
        <div className="homepage-content-technology-container">
          <div className="homepage-content-technology-title">
            Technology Inspiring Greatness Globally
          </div>
          <div className="homepage-content-technology-description">
            A free video-driven interactive work-based learning platform
          </div>
          <div
            className="homepage-content-technology-paragraph-container"
            style={{
              backgroundImage: `url(/homepage-content-technology-background.png)`,
            }}
          >
            <div className="homepage-content-technology-paragraph-title">
              A safe and private career-based platform for our schools and
              businesses.
            </div>
            <div className="homepage-content-technology-paragraph-texts">
              <div>
                Students are migrating through school without an understanding
                of the careers available to them or the necessary skills
                expected of them for a chosen career path.
              </div>
              <div>
                Our schools are resource strained and ill-equipped to build a
                business network to meet workforce objectives.
              </div>
              <div>
                Through meticulously crafted, premium experiences, we believe
                the most effective way to support our school's career
                development objectives is to bring national and local
                businesses, inspirational role models and industry leaders into
                the classroom virtually.
              </div>
            </div>
            <a
              href="https://event.tiggbee.com/authentication/createaccount"
              className="homepage-content-technology-paragraph-button"
            >
              Create account
            </a>
          </div>
        </div>
      </>
    )
    return content
  }

  setEducationObjectivesPart = () => {
    const content = (
      <>
        <div className="homepage-content-education-objectives-container container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="homepage-content-education-objectives-color-background"></div>
              <img
                alt=""
                className="homepage-content-education-objectives-img"
                src={"/home-page-banner_2.png"}
              />
            </div>
            <div className="col-md-6">
              <div className="homepage-content-education-objectives-paragraph-container">
                <div className="homepage-content-technology-paragraph-title">
                  Clarifying education objectives
                </div>
                <div className="homepage-content-technology-paragraph-texts">
                  <div>
                    Students learn how their education impacts their future
                    career opportunities as they engage in the stories,
                    successes, skill requirements, and career insights of
                    industry professionals.
                  </div>
                </div>
                <div>
                  <div className="homepage-content-education-objecttive-item">
                    <div className="homepage-content-education-objecttive-item-icon-container">
                      <img
                        alt=""
                        src={"/home-education-objectives-icon1.png"}
                      />
                    </div>
                    <span>Protects the Teacher - Student Relationship.</span>
                  </div>
                  <div className="homepage-content-education-objecttive-item">
                    <div className="homepage-content-education-objecttive-item-icon-container">
                      <img
                        alt=""
                        src={"/home-education-objectives-icon2.png"}
                      />
                    </div>
                    <span>Brings industry into the classroom.</span>
                  </div>
                  <div className="homepage-content-education-objecttive-item">
                    <div className="homepage-content-education-objecttive-item-icon-container">
                      <img
                        alt=""
                        src={"/home-education-objectives-icon3.png"}
                      />
                    </div>
                    <span>Delivers knowledge, hope and opportunity.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
    return content
  }

  setOpportunityPart = () => {
    const content = (
      <>
        <div className="homepage-content-opportunity-container">
          <div className="homepage-content-technology-title">
            Creating Opportunity
          </div>
          <div className="homepage-content-technology-description">
            Tiggbee bridges the gap between Education and Business
          </div>
          <div className="homepage-content-technology-paragraph-container homepage-content-opportunity-paragraph-container">
            <div className="homepage-content-technology-paragraph-texts">
              {/* <div>
                Young people become disconnected when schools are in communities
                with little or no industry.
              </div>
              <div>
                Many of our students are hindered given the economic or
                geographic barriers that exist.
              </div> */}
              <div>
                Economic and geographical disparities within a community can
                impede a student's academic and personal development, ultimately
                fostering a sense of isolation and disregard.
              </div>
              <div>
                Tiggbee has the power to remove these obstacles by enriching
                educational experiences through a secure and free platform for
                schools. Students can effortlessly connect and interact with a
                wide range of businesses across different industries. Our
                platform unlocks a realm of opportunities, empowering students
                to gain invaluable insights through engaging interactions with
                experienced industry professionals. It enables them to explore a
                wide array of career paths and expand their professional
                networks, paving the way for a future filled with limitless
                potential.
              </div>
            </div>
            <a
              href="https://event.tiggbee.com/authentication/createaccount"
              className="homepage-content-technology-paragraph-button"
            >
              Create account
            </a>
            <div className="homepage-content-opportunity-fact-containter">
              <div className="homepage-content-opportunity-fact-info">
                {/* <div className='homepage-content-opportunity-fact-number'>Fact: 41<sup>%</sup></div>
                            <div className='homepage-content-opportunity-fact-text'>of high school graduates do not go to college.</div>
                            <div className='homepage-content-opportunity-fact-link'>https://nces.ed.gov</div> */}
                <div className="homepage-content-opportunity-fact-number">
                  We develop Hope...
                </div>
                <div className="homepage-content-opportunity-fact-text">
                  by helping our youth find the path to their dreams
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
    return content
  }

  render() {
    const html = (
      <>
        {this.setTechnologyPart()}
        {this.setEducationObjectivesPart()}
        {this.setOpportunityPart()}
        <PartnerScroller />
      </>
    )
    return html
  }
}
