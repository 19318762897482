import React from 'react';
import './banner.css';

export class HomepageBannerContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roles: [
                {
                    logo: 'school-logo.png',
                    title: 'Schools',
                    text: 'educate and inform students of the opportunities that lie ahead.'
                },
                {
                    logo: 'student-logo.png',
                    title: 'Students',
                    text: 'access a multitude of informative and interactive video events from leading businesses.'
                },
                {
                    logo: 'business-logo.png',
                    title: 'Businesses',
                    text: 'share experience and knowledge with the talent of tomorrow.'
                }
            ]
        }
    }

    setRoleContent = (logo, title, text, index) => {
        const content = <div className='role-logo-container'>
            <div className={`role-logo-div-${index}`}>
                <img alt='' className='role-logo'
                    src={`/${logo}`}
                />
            </div>
            <div>
                <span className='role-title'>{title}</span> <span className='role-text'>{text}</span>
            </div>
        </div>;
        return content;
      }

    render() {
        const html = <>
            <div className='banner-content'>
                <div className='homepage-banner-background'></div>
                <div className='homepage-banner-content'>
                    <div className='banner-title homepage-banner-title'>Students and schools create meaningful connections with the business world</div>
                    <div className='container-fluid homepage-banner-role-group'>
                    <div className='homepage-banner-role-group-background'></div>
                    <div className='row'>
                        {
                            this.state.roles.map((role, index) => 
                                <div className='col-md-4' key={index}>{ this.setRoleContent(role.logo, role.title, role.text, index) }</div>
                            )
                        }
                    </div>
                    </div>
                </div>
            </div>
        </>;
        return (
            html
        );
    }
}