import React from "react"
import ReactDOM from "react-dom"
import "./partner-scroller.css"
import { BaseService } from "../services/base-service"

export class PartnerScroller extends React.Component {
  marqueeScrollamount = 10

  constructor(props) {
    super(props)

    this.state = {
      partners: [],
      scrollamount: this.marqueeScrollamount,
      marqueeWidth: 0,
      scrollerContent: null,
    }
  }

  componentDidMount = () => {
    const url = BaseService.getHomepagePartnerScrollerDataURL()
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data?.partners?.length && data.partners.length > 0)
          this.setState({ partners: data.partners })
      })
      .catch((error) => {
        console.error("Error get data:", error)
      })
    this.getMarqueeWidth()
  }

  getMarqueeWidth = () => {
    const marqueeWidth =
      document.getElementsByClassName("layout-container")[0].clientWidth - 30
    this.setState({ marqueeWidth })
  }

  renderPartnerLogoImgs = () => {
    const imgs = Array.apply(null, { length: 10 }).map((e, i) => (
      <div className="partner-group-div">
        {this.state.partners
          //   .filter((t, i) => i < 1)
          .map((partner) => (
            <a href={partner.url ?? "#"} target="_blank">
              <img
                width="250px"
                alt={partner.title}
                title={partner.title}
                src={BaseService.getHomepagePartnerScrollerLogoURL(
                  partner.logoFileName
                )}
              />
            </a>
          ))}
      </div>
    ))
    return imgs
  }

  renderPartnerScroller = () => {
    if (!this.state.scrollerContent)
      this.setState({ scrollerContent: this.renderPartnerLogoImgs() })
    const scroller = (
      <>
        <div className="homepage-content-opportunity-container">
          <div className="homepage-content-technology-title">
            Our Growing List of Industry Partners
          </div>
          <div className="homepage-content-technology-description">
            Building Relationships Between Our School and Industry
          </div>
          {/* <div>Marquee Width: {this.state.marqueeWidth}</div> */}
          <marquee
            id="PartnerMarquee"
            className="partner-scroller-marquee"
            direction="left"
            behavior="scroll"
            scrollamount={this.state.scrollamount}
            onMouseOver={(e) => {
              document.getElementById("PartnerMarquee") &&
                document.getElementById("PartnerMarquee").stop()
            }}
            onMouseOut={(e) => {
              document.getElementById("PartnerMarquee") &&
                document.getElementById("PartnerMarquee").start()
            }}
          >
            <div className="partner-scroller-wrapper-div">
              {this.state.scrollerContent}
            </div>
          </marquee>
        </div>
      </>
    )
    return scroller
  }

  render() {
    const html = (
      <>
        {this.state.partners?.length && this.state.partners.length > 0 ? (
          this.renderPartnerScroller()
        ) : (
          <></>
        )}
      </>
    )
    return html
  }
}
